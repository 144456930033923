import { Controller } from '@hotwired/stimulus'
import { broadcastEvent } from '../../common/dispatch-event/dispatch-event'

export default class extends Controller {
  static targets = ['cost', 'type', 'submit']

  declare readonly costTarget: HTMLInputElement
  declare readonly typeTarget: HTMLSelectElement
  declare readonly submitTarget: HTMLInputElement

  handleChange(): void {
    const value = this.typeTarget.options[this.typeTarget.selectedIndex].dataset.price

    if (value === null || value === undefined) {
      this.submitTarget.setAttribute('disabled', 'disabled')
      this.submitTarget.value = 'No valid rate found'
      broadcastEvent<{ value: number }>(this.costTarget, 'update', { value: parseFloat(0) })
    } else {
      this.submitTarget.removeAttribute('disabled')
      this.submitTarget.value = 'Save'
      broadcastEvent<{ value: number }>(this.costTarget, 'update', { value: parseFloat(value) })
    }
  }
}
